
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            

.dropDown {
  position: relative;

  select {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
}

.label {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
}

.default {
  color: $pink;
}

.dropBtn {
  border: 1px solid $gray;
  text-align: start;
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  background: none;
  color: $black;

  &:after {
    background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 9.99984L16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984Z" fill="%23d70059"/></svg>') no-repeat center;
    content: "";
    position: absolute;
    right: 16px;
    top: 67%;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    transition: all .4s ease;
  }

  
  &.opened {
    &:after {
      transform: rotate(-180deg);
    }
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.placeholder {
  color: $gray;
}

.list {
  position: fixed;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 12px;
  overflow: auto;
  max-height: 250px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  margin-top: 4px;
  transition: visibility .4s ease, opacity .4s ease;
  
  &.opened {
    opacity: 1;
    visibility: visible;
  }

  ul {padding: 8px 0;}
}

.flipList {
  transform: translateY(calc(-100% - 52px));
  margin-top: -4px;
}

.option {
  display: block;
  width: 100%;
  padding: 4px 16px;
  background: none;
  border: none;
  color: $black;
  cursor: pointer;
  text-align: start;
  transition: all .4s ease;

  &:hover {
    background: rgba(255, 113, 149, 0.2);
  }
}

.searchInput {
  display: block;
  width: 100%;
  padding: 16px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  font-size: 14px;
  position: sticky;
  top:0;
}
